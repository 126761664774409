// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === "production" && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN || "https://526f120760634b418439344832372ef3@o1262166.ingest.sentry.io/6453279",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    environment: process.env.NODE_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeSend(event, hint) {
      const error = hint?.originalException;
      if (error && typeof error !== "string") {
        switch (error.name) {
          // case "AuthenticationError":
          // case "AuthorizationError":
          // case "NotFoundError":
          case "ChunkLoadError":
            return null;
        }
      }
      return event;
    }
  });
}
