import React from "react";
import { AuthenticationError, useMutation } from "blitz";
import { VStack, Heading, Flex } from "@chakra-ui/react";
import { Form, FORM_ERROR, InputField } from "chakra-form";

import login from "app/auth/mutations/login";
import { Login } from "app/auth/validations";

type LoginFormProps = {
  onSuccess?: () => void;
  buttons?: React.ReactNode;
};

export const LoginForm = (props: LoginFormProps) => {
  const { buttons, onSuccess } = props;
  const [loginMutation] = useMutation(login);

  return (
    <Flex h="full" w="full" align="center" justify="center">
      <VStack>
        <Heading>Login</Heading>

        <Form
          submitText="Login"
          schema={Login}
          buttonsLeft={buttons}
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values) => {
            try {
              await loginMutation(values);
              onSuccess?.();
            } catch (error) {
              if (error instanceof AuthenticationError) {
                return { [FORM_ERROR]: "Sorry, those credentials are invalid" };
              } else {
                return {
                  [FORM_ERROR]:
                    "Sorry, we had an unexpected error. Please try again. - " +
                    (error as any).toString()
                };
              }
            }
          }}
        >
          <InputField name="email" label="Email" placeholder="Email" />
          <InputField name="password" label="Password" placeholder="Password" type="password" />
          {/* <Link href="/forgot-password">Forgot your password?</Link> */}
        </Form>

        {/* <Link href="/signup">Sign Up</Link> */}
      </VStack>
    </Flex>
  );
};
