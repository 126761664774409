import { z } from "zod";

export const passwordSchema = z.string().min(6).max(100);

export const Signup = z.object({
  email: z.string().email(),
  password: passwordSchema
});

export const Login = z.object({
  email: z.string().email(),
  password: z.string()
});

export const ForgotPassword = z.object({
  email: z.string().email()
});

export const ResetPassword = z
  .object({
    password: passwordSchema,
    passwordConfirmation: passwordSchema,
    token: z.string()
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords don't match",
    path: ["passwordConfirmation"] // set the path of the error
  });

export const ChangePassword = z.object({
  currentPassword: z.string().optional(),
  newPassword: passwordSchema
});
