import {
  AppProps,
  AuthenticationError,
  AuthorizationError,
  ErrorBoundary,
  ErrorComponent,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  useRouter
} from "blitz";
import React, { Suspense, useEffect } from "react";
import { Box, ChakraProvider, Spinner, theme } from "@chakra-ui/react";
import { ConfirmContextProvider } from "chakra-confirm";

import { LoginForm } from "app/auth/components/LoginForm";
import { ImpersonatingUserNotice } from "app/auth/components/ImpersonatingNotice";
import { GlobalStyles } from "app/core/theme/GlobalStyles";

const InnerApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
      // onError={(error, componentStack) => {
      //   Sentry.captureException(error, { contexts: { react: { componentStack } } });
      // }}
    >
      <ConfirmContextProvider>
        {getLayout(
          <>
            <GlobalStyles />
            <ImpersonatingUserNotice />
            <Component {...pageProps} />
          </>
        )}
      </ConfirmContextProvider>
    </ErrorBoundary>
  );
};

export default function App(props: AppProps) {
  return (
    <ChakraProvider
      theme={theme}
      colorModeManager={{ get: () => "light", set: () => {}, type: "cookie" }}
    >
      {/* <I18nProvider defaultLang={Language.en}> */}
      <Box minH="100vh">
        <style>
          {`
            .render a {
              text-decoration: underline !important;
              color: #0070f3 !important;
            }
          `}
        </style>

        <Suspense fallback={<Spinner />}>
          <InnerApp {...props} />
        </Suspense>
      </Box>
      {/* </I18nProvider> */}
    </ChakraProvider>
  );
}

const RootErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const router = useRouter();

  useEffect(() => {
    if (error instanceof AuthorizationError) {
      if (router.asPath !== "/") {
        router.push("/").then(() => resetErrorBoundary());
      }
    }
  }, [error, resetErrorBoundary, router]);

  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />;
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    );
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    );
  }
};
