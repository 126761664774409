import { css, Global } from "@emotion/react";

export const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          background: none;
        }
        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
          border-radius: 4px;
        }
      `}
    />
  );
};
