import React, { Suspense } from "react";
import { invoke, useSession, queryClient, useRouter } from "blitz";
import { Text, Flex, Spinner, Button, useColorModeValue } from "@chakra-ui/react";
import { destroyCookie } from "nookies";

import stopImpersonatingFn from "app/auth/mutations/stopImpersonating";
import { useCurrentUser } from "app/core/hooks/useCurrentUser";

const User = () => {
  const user = useCurrentUser();
  if (!user) {
    return null;
  }

  return <Text as="span" fontWeight="bold" d="inline">{`${user.email}`}</Text>;
};

export const ImpersonatingUserNotice = () => {
  const session = useSession();
  const router = useRouter();

  const bg = useColorModeValue("yellow.300", "yellow.700");

  if (!session.impersonatingFromUserId) return null;

  const stopImpersonating = async () => {
    await router.push("/");
    destroyCookie(null, "sb_uid");
    await invoke(stopImpersonatingFn, {});
    queryClient.clear();
  };

  return (
    <Flex h="64px" w="full" bg={bg} alignItems="center" justifyContent="center">
      <Text as="div">
        Currently impersonating user{" "}
        <Suspense fallback={<Spinner />}>
          <User />
        </Suspense>
      </Text>

      <Button
        aria-label="Stop impersonating user"
        ml="4"
        size="sm"
        onClick={stopImpersonating}
        colorScheme="red"
      >
        Exit
      </Button>
    </Flex>
  );
};
